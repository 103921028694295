.sso-portal-project,
.sso-portal-instance {
	margin: 20px 0;
	border-radius: 0;
	border-color: transparent;
	background-color: #eee;
	border-bottom: 1px solid #ccc;
}

.sso-portal-project p,
.sso-portal-instance p {
	color: #999;
	font-size: 13px;
	line-height: 20px;
}

.portal-project-image-container {
	background: rgba(0, 0, 0, .08);
	border: 1px solid #587594;
	min-height: 100px;
	position: relative;
}

.portal-project-image-container p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-shadow: 0 0px 4px rgb(0 0 0 / 60%);
	font-size: 63px;
	font-weight: bold;
	line-height: 100%;
	margin: 0;
	padding: 0;
	color: transparent;
}

.sso-portal-instance {
	margin: 5px 0;
	overflow: hidden;
	padding: 10px 0;
}

.sso-portal-project .panel-heading,
.sso-portal-instance .panel-heading {
	background-color: #587594;
	border-color: #587594;
	border-radius: 0;
	position: relative;
}

.sso-portal-project__image {
	width: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 90%;
	box-shadow: 0 0 8px 3px rgba(0, 0, 0, .2);
}

.sso-portal-project__headline,
.sso-portal-instance__headline {
	margin-bottom: 5px;
	display: inline;
}

.sso-portal-project__text,
.sso-portal-instance__text {
	display: block;
	padding: 0 0 10px 0;
}

.sso-portal-project__text p,
.sso-portal-instance__text p {
	display: inline;
}

.sso-portal-project__msg {
	line-height: 34px;
	display: block;
}

.sso-portal-project__msg-count {
	background-color: #587594;
	border-color: #587594;
	margin-left: 5px;
}

.portal-profile__fieldset {
	min-width: 0;
	margin: 0;
	border: 0;
	padding: 0 5%;
	width: 100%;
	clear: both;
}

.portal-profile__legend {
	display: block;
	width: 100%;
	font-size: 21px;
	line-height: inherit;
	color: #333;
	border: 0;
	border-bottom: 1px solid #e5e5e5;
	overflow: hidden;
	float: left;
	padding: 20px 0 5px;
	margin-bottom: 25px;
}

.portal-profile__group {
	width: 100%;
	overflow: hidden;
	margin: 15px 0 0;
}

.portal-profile__group label {
	text-align: right;
	line-height: 33px;
	margin: 0;
}

.portal-profile__desc p {
	cursor: not-allowed;
	min-height: 33px;
	display: block;
	color: #777;
	line-height: 26px;
}

.portal-profile__text {
	margin: 0;
	padding: 5px 8px;
}

.portal-profile__gender:not(.selected) {
	display: none;
}

@media screen and (max-width: 580px) {
	.sso-portal-project__msg {
		width: 100%;
		text-align: right;
		margin-bottom: 10px;
	}
	
	.sso-portal-project .panel-body {
		padding: 15px 0;
	}
}

@media screen and (max-width: 992px) {
	
	.portal-profile__group,
	.portal-profile__group label {
		margin: 0;
		padding: 0;
	}
	
	.portal-profile__desc {
		min-height: 38px;
	}
}

@media screen and (min-width: 768px) {
	.sso-portal-project__headline {
		display: block;
	}
	
	.portal-profile__fieldset {
		padding: 0 15%;
	}
	
}