.mc-jobs {
    padding-top: 20px;
}

.mc-jobs .mc-jobs__panel {
    border-radius: 0;
    background-color: transparent;
    border-color: #ccc;
    margin: 10px auto;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.mc-jobs .mc-jobs__panel.is--success {
    border-color: #3c763d;
}

.mc-jobs .mc-jobs__panel.is--error {
    border-color: #a94442;
}

.mc-jobs .mc-jobs__heading {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-color: transparent;
}

.mc-jobs .panel-title {
    position: relative;
}

.mc-jobs .panel-title a {
    display: block;
    padding: 10px;
    padding-right: 30px;
    position: relative;
    box-sizing: border-box;
}

.mc-jobs .is--success .panel-title {
    background-color: #dff0d8;
    color: #3c763d;
}

.mc-jobs .is--error .panel-title {
    background-color: #f2dede;
    color: #a94442;
}

.mc-jobs .mc-jobs__heading .table {
    margin: 0;
}

.mc-jobs .mc-jobs__tasks__table {
    border-top: 1px solid #ccc;
    padding: 15px 15px;
    max-height: 580px;
    overflow: auto;
}

.mc-jobs .mc-jobs__tasks:after {
    content: "";
    position: relative;
    width: 100%;
    height: 100px;
    display: block;
}


.mc-jobs .mc-jobs__tasks.collapse.in:after {
    display: none;
}

.mc-jobs .mc-jobs__tasks.collapse.in{
    min-height: 604px;
    position: relative;
}

.mc-jobs .mc-jobs__tasks.collapse.in.count--tasks-1{
    min-height: 349px;
}

.mc-jobs__tasks__header {
    display: flex;
    flex-direction: row;
    padding: 15px 60px 10px 20px;
    position: relative;
    align-items: center;
    box-sizing: border-box;
}

.mc-jobs__tasks__header__reload {
    position: absolute;
    right: 15px;
    top: 19px;
    background: none;
    padding: 0 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.mc-jobs__tasks__header__reload:before {
    font-size: 21px;

}

.mc-jobs__tasks__header__filter {
    align-items: center;
    box-sizing: border-box;
}

.mc-jobs__tasks__header__filter__status {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    color: #333;
    background-color: #fff;
    padding: 5px 10px;
    border: 1px solid #ddd;
    margin: 0 0 0 8px;
    opacity: .9;
}

.mc-jobs__tasks__header__filter__status:disabled {
    opacity: 1;
    cursor: default;
}

.mc-jobs__tasks__header__filter__status.is--active {
    border: 2px solid #ccc;
    background-color: #ccc;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}

.mc-jobs__tasks__header__filter__status.is--success {
    background-color: #dff0d8;
}

.mc-jobs__tasks__header__filter__status.is--success.is--active {
    border-color: #3c763d;
    background-color: #3c763d;
    color: #fff;
}

.mc-jobs__tasks__header__filter__status.is--error {
    background-color: #f2dede;
}

.mc-jobs__tasks__header__filter__status.is--error.is--active {
    border-color: #a94442;
    background-color: #a94442;
    color: #fff;
}

.mc-jobs__tasks__header__filter__status.is--running {
    background-color: #f5f5f5;
}

.mc-jobs__tasks__header__filter__status.is--running.is--active {
    border-color: #333;
    background-color: #333;
    color: #fff;
}

.mc-jobs__tasks__table__count-tasks {
    background: #f5f5f5;
    padding: 2px 5px;
    box-sizing: border-box;
    border: 1px solid #ddd;
}

.mc-jobs .mc-jobs__tasks__table .table {
    margin: 0;
    border: 1px solid #ccc;
}

.mc-jobs .arrow-icon {
    transform: translateY(-50%);
    border: none;
    background: none;
    font-size: 15px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 15px;
    top: 50%;
}

.mc-jobs .arrow-icon:before {
    transform: rotate(-90deg);
    position: relative;
    display: block;
}

.mc-jobs .collapsed .arrow-icon:before {
    transform: rotate(90deg);
}

.mc-jobs .loading-animation {
    padding: 0;
    width: 30px;
    height: 30px;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mc-jobs .loading-animation .loading-animation__icon {
    width: 30px;
    height: 30px;
}

.alert-info {
    width: 100%;
}

.btn-filter-jobs {
    background: none;
    background-color: transparent;
    border: 1px solid #ddd;
    color: #587594;
}

/*
Navigation
 */

.dropdown-menu > li > a.mc-jobs-nav-link {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 35px 5px 10px;
    position: relative;
}

.mc-jobs-nav-link .mc-jobs-nav-link__badge {
    position: absolute;
    right: 5px;
    top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    background: #ddd;
    width: 30px;
    text-align: center;
    line-height: 26px;
    padding: 0 3px;
    border-radius: 5px;
}

.mc-jobs-nav-link .mc-jobs-nav-link__badge.is--success {
    background-color: #dff0d8;
}

.mc-jobs-nav-link .mc-jobs-nav-link__badge.is--error {
    background-color: #f2dede;
}

/*
JSON printing
 */
pre {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px;
    outline: none;
    border-radius: 0;
}

pre .string {
    color: green;
}

pre .number {
    color: darkorange;
}

pre .boolean {
    color: blue;
}

pre .null {
    color: magenta;
}

pre .key {
    color: red;
}



.mass-configuration-upload {
    overflow: hidden;
}

.mass-configuration-upload .loading-animation {
    display: none;
}

.mass-configuration-upload .is--loading .loading-animation {
    display: block;
}

.mass-configuration-upload .import {
    font-size: 14px;
    padding: 40px 10px;
}

.mass-configuration-upload .has-advanced-upload .import__icon {
    margin-bottom: 20px;
    font-size: 40px;
}

.mass-configuration-upload .import__select-button {
    font-size: 14px;
    cursor: pointer;
}

.mass-configuration-upload .import__file {
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}

.mc-user-upload-result > div {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

/*.mc-upload-result {*/
/*    padding: 10px;*/
/*    background: rgba(0, 0, 0, .04);*/
/*    border: 1px solid rgba(0, 0, 0, .1);*/
/*    box-sizing: border-box;*/
/*}*/

.mc-upload-result__general-inf {
    text-align: left;
    padding-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.mc-upload-result__general-inf > div {
    padding: 5px;
    border: 1px solid #ddd;
    margin: 0 5px 0 0;
}

.mc-upload-result__general-inf > div:last-of-type {
    margin: 0;
}

.panel.mc-upload-result__panel ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.panel.mc-upload-result__panel {
    box-shadow: none;
    border: 1px solid #3c763d;
    border-radius: 0;
    margin-bottom: 10px;
}

.panel.mc-upload-result__panel:last-of-type {
    margin-bottom: 0;
}

.panel.mc-upload-result__panel.is--errors {
    border: 1px solid #a94442;
}

.panel.mc-upload-result__panel.is--ignored {
    border: 1px solid #8a6d3b;
}

.panel.mc-upload-result__panel > .mc-upload-result__heading {
    box-shadow: none;
    color: #3c763d;
    background-color: #dff0d8;
    border: none;
    text-align: left;
}

.panel.mc-upload-result__panel.is--errors > .mc-upload-result__heading {
    color: #a94442;
    background-color: #f2dede;
}

.panel.mc-upload-result__panel.is--ignored > .mc-upload-result__heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
}

.panel.mc-upload-result__panel .panel-title > a {
    display: block;
    width: 100%;
    text-align: left;
    padding-right: 50px;
    position: relative;
}

.panel.mc-upload-result__panel > .panel-heading .badge {
    position: absolute;
    right: 0;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #777;
    background-color: #fff;
}


.panel.mc-upload-result__panel .panel-collapse {
    max-height: 260px;
    overflow: auto;
    padding: 0 10px 10px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
}

.panel.mc-upload-result__panel table th,
.panel.mc-upload-result__panel table td {
    text-align: left;
}