/*
* Error page
*/
.sso-404 {
    width: 100%;
    text-align: center;
    border: 1px solid #ddd;
    padding: 40px 20px;
    margin: 30px 0;
}

.sso-404__inner {
    max-width: 430px;
    margin: 0 auto;
}

.sso-404__icon {
    display: block;
    font-size: 56px;
}

.sso-404__list {
    text-align: left;
}
