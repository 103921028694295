/*
* Paginator
*/

.sso-paginator {
	float: left;
	width: 100%;
}

.sso-pagination__paginator {
	text-align: center;
}

.sso-pagination__left,
.sso-pagination__right,
.sso-pagination__middle {
	margin: 10px 0 0;
	padding: 5px;
}

.sso-pagination__left {
	padding-left: 0;
	font-size: 14px;
	line-height: 24px;
	font-weight: bold;
	position: relative;
}

.sso-pagination__right {
	padding-right: 0;
	text-align: right;
}

.sso-pagination__total {
	display: inline-block;
	margin: 0 10px 5px 0;
	float: left;
}

.sso-pagination__left .items-founded {
	display: inline-block;
	height: 34px;
	border: 1px solid #ddd;
	padding: 4px 10px;
	float: left;
}

.sso-pagination__left .badge {
	font-size: 12px;
	background-color: #587594;
	border-radius: 0;
	padding: 11px;
}

.sso-column {
	float: left;
}

.sso-column__container {
	display: none;
}

.sso-column__container .dropdown-menu {
	display: block;
}

.sso-column__list {
	width: auto;
	overflow: hidden;
	border-radius: 0;
}

.sso-column__bg {
	background: rgba(255, 255, 255, .2);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 5;
	cursor: pointer;
}

.sso-column .sso-column__label {
	border-bottom: 1px solid #ddd;
	width: 100%;
	display: block;
	padding: 0 20px 10px 40px;
}

.sso-column .sso-column__input {
	margin: 6px 5px 0px -20px;
}

.sso-column .sso-column__button {
	padding: 4px 4px 3px 6px;
	font-size: 23px;
	border: 1px solid #ddd;
	margin: -1px 0;
	display: block;
	cursor: pointer;
	color: #587594;
}

.sso-column .sso-column__save {
	text-align: center;
}

.sso-column .sso-column__save,
.sso-column__title {
	background: #eee;
	display: block;
	overflow: hidden;
	margin: -10px 0;
	padding: 10px 10px 15px 10px;
}

.sso-column__title {
	margin: -5px 0 0;
	padding: 10px 20px 0;
	border-bottom: 1px solid #ddd;
}

.is--bottom-paginator .dropdown-menu {
	top: auto;
	bottom: 100%;
}

.sso-pagination__right-listing {
	position: relative;
	overflow: hidden;
	height: 35px;
	border: 1px solid #ddd;
	width: 85px;
	float: right !important;
	margin-left: 20px;
	display: block !important;
}

.sso-pagination .sso-pagination__right__field .input-group-btn {
	width: 1% !important;
}

.sso-pagination .sso-pagination__right__field .form-control {
	font-size: 12px;
}

.sso-pagination .input-group .sso-pagination__right__field__select {
	left: 0;
	background: transparent;
	position: absolute;
	display: block;
	border: 0;
	box-shadow: none;
	width: 105px;
}

.sso-pagination .sso-pagination__right__field__list-icon {
	display: inline-block;
	float: none;
	position: absolute;
	right: 7px;
	top: 7px;
	z-index: 0;
	font-size: 20px;
	color: #587594;
}

.pagination {
	margin: 0;
}

.pagination > .disabled > a,
.pagination > .disabled > a:hover {
	opacity: .5;
}

.pagination > li.is--hidden > a {
	width: 0px;
	padding: 6px 1px;
	text-indent: -999999999px;
	overflow: hidden;
}

.pagination.is--too-long > li.is--hidden:nth-child(2n) > a,
.pagination.is--extra-long > li.is--hidden > a {
	display: none;
}

.pagination.is--extra-long > li.is--first:not(.active) > a {
	margin-right: 5px;
}

.pagination.is--extra-long > li.is--last:not(.active) > a {
	margin-left: 5px;
}

.pagination > li {
	display: inline;
}

.pagination > li > a {
	color: #587594;
	margin-bottom: 4px;
}

.pagination > li:first-child > a,
.pagination > li:last-child > a {
	margin-top: -1px;
}

.pagination > li.active > a,
.pagination > li.active > a:hover {
	background-color: #587594;
	border: 1px solid #587594;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span {
	border-radius: 0;
}

@media screen and (max-width: 580px) {

	.sso-pagination__left,
	.sso-pagination__right,
	.sso-pagination__middle {
		margin: 5px 0 0;
		padding: 0;
	}

	.sso-pagination .sso-pagination__right {
		max-width: 110px;
	}

	.sso-pagination .sso-pagination__right-listing,
	.sso-pagination .sso-pagination__right__field {
		width: 100%;
		margin-bottom: 5px;
	}

	.sso-pagination .input-group .sso-pagination__right__field__select {
		max-width: 125px;
		width: 125px;
	}

	.pagination > li > a,
	.pagination > li > span {
		padding: 6px 8px;
	}

	.pagination > li.is--hidden > a {
		display: none !important;
	}

	.pagination > li.is--first:not(.active) > a {
		margin-right: 5px;
	}

	.pagination > li.is--last:not(.active) > a {
		margin-left: 5px;
	}
}
