/*
* Notifications
*/

.sso-feedback .glyphicon-ok-sign,
.sso-feedback .glyphicon-question-sign,
.sso-feedback .glyphicon-info-sign {
	display: none;
}

.sso-feedback {
	border-radius: 0;
}

.sso-feedback .modal-header {
	padding: 15px 15px 5px;
	border-color: transparent;
}

.sso-feedback .modal-header .close {
	color: #587594;
	opacity: 1;
}

.sso-feedback__icon-bg {
	position: absolute;
	left: 20px;
	top: 50%;
	margin-top: -20px;
}

.sso-feedback__icon {
	font-size: 40px;
}

.sso-feedback__text {
	padding: 0 15px 10px 80px;
	margin: 0;
	position: relative;
	min-height: 30px;
}

.sso-feedback.bg-success {
	background-color: #dff0d8;
	color: #666;
}

.bg-success .glyphicon-ok-sign {
	display: block;
	color: #ADCD6A;
}

.sso-feedback.bg-info {
	background-color: #d9edf7;
	color: #666;
}

.sso-feedback.bg-warning {
	background-color: #fcf8e3;
	color: #666;
}

.bg-info .glyphicon-info-sign,
.bg-warning .glyphicon-info-sign {
	display: block;
	color: #587594;
}

.sso-feedback.bg-danger {
	background-color: #f2dede;
}

.bg-danger .glyphicon-question-sign {
	display: block;
	color: #a94442;
}

.modal-content {
	-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
	box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
}

/*
* show-discreet
*/

.show-discreet .sso-feedback .modal-header {
	position: absolute;
	right: 10px;
	top: 50%;
	margin: 0;
	padding: 0;
	margin-top: -6px;
	z-index: 2;
}

.show-discreet .sso-feedback .modal-header .close {
	font-size: 14px;
}

.show-discreet .sso-feedback__text {
	padding: 12px 40px 12px 50px;
}

.show-discreet .sso-feedback__text.text-right {
	display: none;
}

.show-discreet .modal-backdrop {
	display: none;
}

.modal-backdrop + .modal-backdrop {
	visibility: hidden;
}

.show-discreet .sso-feedback__icon {
	font-size: 30px;
	margin-top: -15px;
	left: 10px;
}

.modal-open {
	overflow: auto;
	padding-right: 0 !important;
}

.show-discreet .modal-dialog {
	margin: 0;
	width: auto;
}

.show-discreet.modal {
	padding: 0;
	top: 77px;
	bottom: auto;
	left: 50%;
	right: auto;
	overflow: visible;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}

.alert {
	overflow: hidden;
	line-height: 30px;
}

.alert .btn {
	margin: 0;
}

.alert .alert__text {
	display: inline-block;
	line-height: 20px;
}