.mail-log {
	padding: 0;
	/*border: 1px solid #ddd;*/
	margin: 0 auto;
	display: inline-block;
}

.mail-log div {
	position: relative;
}

.mail-log .transition,
.mail-log .mail-log-item i:before,
.mail-log .mail-log-item i:after {
	transition: all 0.25s ease-in-out;
}

.mail-log .flipIn,
.mail-log .mail-log-item {
	animation: flipdown 0.5s ease both;
}

.mail-log .no-select {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mail-log-details {
	color: rgba(48, 69, 92, 0.8);
	font-size: 17px;
	line-height: 26px;
	letter-spacing: 1px;
	position: relative;
	overflow: hidden;
	/*max-height: 800px;*/
	opacity: 1;
	transform: translate(0, 0);
	z-index: 2;
	display: block;
	padding: 20px;
}

.mail-log .mail-log {
	list-style: none;
	perspective: 900;
	padding: 0;
	margin: 0;
}

.mail-log .mail-log-item {
	position: relative;
	padding: 0;
	border: 1px solid rgba(88, 117, 148, .5);
	margin: 30px auto;
	background: rgba(0, 0, 0, .02);
}

.mail-log-detail-item {
	background: #fff;
	overflow: hidden;
	font-size: 12px;
	border: 1px solid rgba(88, 117, 148, .3);
	padding: 10px 15px 15px;
}

.mail-log .mail-log-item-label {
	color: #587594;
	font-weight: normal;
	padding-top: 5px;
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 24px;
}

.mail-log-value {
	background: rgba(0, 0, 0, .05);
	padding: 8px;
	min-height: 30px;
	border: 1px solid #ddd;
	font-weight: normal;
}

.mail-log-detail-item .mail-log-value {
	border: none;
	font-size: 12px;
	height: 10px;
	line-height: 12px;
	margin: 0 0 5px;
}

/*.mail-log .mail-log-item:last-of-type {*/
/*border-bottom: none;*/
/*}*/

.mail-log .label {
	position: relative;
	display: inline-block;
	padding: 20px 20px 10px 10px;
	width: 100%;
	cursor: pointer;
	font-size: 14px;
	color: #333;
	text-align: left;
	z-index: 1;
}

/*.mail-log .mail-log-item:nth-of-type(1) {*/
/*animation-delay: 0.5s;*/
/*}*/
/*.mail-logr .mail-log-item:nth-of-type(2) {*/
/*animation-delay: 0.75s;*/
/*}*/
/*.mail-log .mail-log-item:nth-of-type(3) {*/
/*animation-delay: 1s;*/
/*}*/

/*.mail-log .mail-log-item:last-of-type {*/
/*padding-bottom: 0;*/
/*}*/

.mail-log .mail-log-item .mail-log-toggle {
	position: absolute;
	/*transform: translate(-6px, 50%);*/
	right: 20px;
	top: 20px;
}

.mail-log .mail-log-item i:before,
.mail-log .mail-log-item i:after {
	content: "";
	position: absolute;
	background-color: #587594;
	width: 3px;
	height: 9px;
}

.mail-log .mail-log-item i:before {
	transform: translate(-2px, 0) rotate(45deg);
}

.mail-log .mail-log-item i:after {
	transform: translate(2px, 0) rotate(-45deg);
}

.mail-log .mail-log-item input[type=checkbox] {
	position: absolute;
	width: 0;
	height: 0;
	opacity: 0;
}

.mail-log .mail-log-item input[type=checkbox]:checked ~ .mail-log-details {
	margin-top: 0;
	max-height: 0;
	opacity: 0;
	transform: translate(0, 50%);
	position: absolute;
}

.mail-log .mail-log-item input[type=checkbox]:checked ~ i:before {
	transform: translate(2px, 0) rotate(45deg);
}

.mail-log .mail-log-item input[type=checkbox]:checked ~ i:after {
	transform: translate(-2px, 0) rotate(-45deg);
}

@keyframes flipdown {
	0% {
		opacity: 0;
		transform-origin: top center;
		transform: rotateX(-90deg);
	}
	5% {
		opacity: 1;
	}
	80% {
		transform: rotateX(8deg);
	}
	83% {
		transform: rotateX(6deg);
	}
	92% {
		transform: rotateX(-3deg);
	}
	100% {
		transform-origin: top center;
		transform: rotateX(0deg);
	}
}

.mail-log .mail-log-feedback {
	margin: 20px auto 0;
}

.mail-log .loading-animation {
	margin: 20px auto 0;
	display: none;
}

.mail-log .is--loading .loading-animation {
	display: block;
}