.orga-admins {
	border: none;
	max-width: 80%;
	margin: 20px auto;
}

.orga-admins .orga-admins__heading {
	background-color: #587594;
	border: 1px solid #587594;
	color: #fff;
	border-radius: 0;
}

.orga-admins .orga-admins__link {
	border: 1px solid #ddd;
	color: #286090;
	border-top: none;
	border-radius: 0;
	background-color: transparent;
	display: block;
	margin-top: 1px;
	padding-right: 40px;
}

.orga-admins .orga-admins__link.is--deactivated {
	background-color: rgba(0, 0, 0, .05);
	font-style: italic;
	color: #666;
}

.orga-admins .orga-admins__link .glyphicon {
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -9px;
	color: #3c763d;
}

.orga-admins .orga-admins__link.is--deactivated .glyphicon {
	color: #a94442;
}

@media screen and (max-width: 768px) {
	.orga-admins {
		max-width: 100%;
	}
}