auth0-blocks-container {
    min-height: 33px;
}

.auth0-blocks-container .auth0-blocks {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    margin: 10px 0;
    position: relative;
    box-sizing: border-box;
    float: left;
    padding: 2px 4px;
}

.auth0-blocks-container .auth0-blocks-item {
    background: #f8f8f8;
    padding: 8px;
    margin: 2px 0;
}

.auth0-blocks-container .auth0-blocks-item-value {
    background: #f0f0f0;
    padding: 4px 6px 3px;
    margin-bottom: 4px;
}

.auth0-blocks-container .auth0-unblocked-message {
    color: #3c763d;
}

.auth0-blocks-container .auth0-unblocked-error {
    color: #a94442;
}