/*
* Error page
*/
.logs {
	background-color: #eee;
	border: 1px solid #587594;
	margin: 20px 0;
	border-left: 4px solid #587594;
}

.logs__title {
	font-size: 16px;
	background: #587594;
	padding: 15px;
	margin: 0 -15px 20px;
	color: #fff;
}

.logs__title a {
	color: #ADCD6A;
}

.logs__content {
	overflow: hidden;
}

.logs__items {
	/*border-left: 5px solid #ddd;*/
	width: 100%;
	float: left;
	display: block;
	overflow: hidden;
}

.logs__item {
	padding-left: 40px;
	word-break: break-word;
}

.logs .logs__item:first-child {
	padding-left: 15px;
	border-radius: 0;
}

.logs .logs__item:last-child {
	border-radius: 0;
}

.logs__item-header {
}

.logs__label {
	position: absolute;
	left: 15px;
	top: 50%;
	width: 18px;
	height: 18px;
	margin-top: -9px;
	line-height: 13px;
}

/*
* Filter
*/

.filter {
	overflow: hidden;
	padding: 15px 0 0;
	text-align: center;
}

.filter__inner {
	padding-right: 40px;
	position: relative;
	display: inline-block;
}

.logs__input-container {
	position: relative;
	float: left;
	width: auto;
	margin: 10px 10px 0 0;
	clear: both;
}

.logs-from-label,
.logs-to-label {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 40px;
	top: 0;
	right: 0;
}

.logs-from-label:before,
.logs-to-label:before {
	position: absolute;
	right: 0;
	top: 0;
	font-size: 20px;
	padding: 8px 5px 5px;
	border-left: none;
	color: #587594;
}

.logs-from__input,
.logs-to__input {
	padding-right: 30px;
}

.logs-filter-btn {
	position: absolute;
	display: inline-block;
	right: 0;
	bottom: 0;
	top: auto;
}

.picker,
.logs-from__input,
.logs-to__input {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

input.picker__input:read-only {
	cursor: pointer;
}

.filter .picker__select--month,
.filter .picker__select--year {
	padding: .2em;
	font-size: 0.7em;
}

.filter .picker__day--today:before,
.filter .picker__button--today:before {
	border-top-color: #587594;
}

.filter .picker__nav--next:before {
	border-left-color: #587594;
}

.filter .picker__nav--prev:before {
	border-right-color: #587594;
}

@media screen and (min-width: 580px) {
	.logs__items {
		width: auto;
		margin-right: 10px;
	}
	
	.filter .picker__select--month,
	.filter .picker__select--year {
		font-size: inherit;
	}
	
	.logs__input-container {
		clear: none;
	}
}