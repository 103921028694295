.user-projects {
    border: none;
    max-width: 80%;
    margin: 15px auto;
}

.user-projects .user-projects__heading {
    background-color: #587594;
    border: 1px solid #587594;
    color: #fff;
    border-radius: 0;
}

.user-projects .user-projects__panel-default {
    border-color: transparent;
    border-radius: 0;
    overflow: hidden;
}

.user-projects__controls {
    width: 100%;
    text-align: right;
    padding: 0 0 20px;
}

.user-projects__controls .glyphicon {
    top: 0;
}

.user-projects__list-item {
    overflow: hidden;
    transition: background-color 1s linear;
}

.user-projects__list-item.has--error {
    background: rgba(217, 83, 79, 0.1);
}

.user-projects__list-item.is--success {
    background: rgba(92, 184, 92, 0.1);
}

.user-projects__name {
    display: inline-block;
    padding-right: 60px;
    position: relative;
}

.user-projects__desc {
    color: #999;
    font-size: 13px;
    line-height: 20px;
    display: block;
}

.user-projects__well {
    margin: 10px 0 5px;
}

.user-projects__label {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    top: -2px;
}

.user-projects__user-state {
    position: relative;
    float: right;
}

.user-projects__user-buttons {
    position: relative;
    float: right;
    width: 100%;
    padding-right: 60px;
    margin: 5px 0 0;
}

.user-projects__user-buttons__text {
    text-align: right;
    padding: 1px 5px 5px 0;
    line-height: 24px;
    margin: 0;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-projects__user-state-ok-sign,
.user-projects__user-state-minus-sign,
.user-projects__user-state-ok-sign:active,
.user-projects__user-state-minus-sign:active {
    top: 0;
    right: 0;
    position: absolute;
    background: none;
    font-size: 25px;
    padding: 1px 2px;
    border-radius: 100%;
    border: none;
    color: #d9534f;
    outline: none;
    z-index: 10;
}

.user-projects__user-state-ok-sign:disabled,
.user-projects__user-state-minus-sign:disabled {
    opacity: .6;
}

.user-projects__user-state-ok-sign,
.user-projects__user-state-ok-sign:active {
    color: #5cb85c;
    right: 28px;
}

.user-projects__user-state-icon {
    border: 1px solid #d9534f;
    width: 40px;
    height: 40px;
    font-size: 35px;
    border-radius: 50%;
    color: #d9534f;
    overflow: hidden;
    margin-right: 9px;
}

.user-projects__user-state-icon:before {
    content: "\e008";
    position: absolute;
    top: 4px;
    right: 2px;
}

.is--active .user-projects__user-state-icon {
    color: #5cb85c;
    border: 1px solid #5cb85c;
}

.user-projects__small-text {
    font-size: 11px;
}

.user-projects__grey-text {
    color: #999;
}

.user-projects__last-update {
    text-align: left;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
}

.panel-default > .panel-heading .user-projects__badge {
    color: #337ab7;
    background-color: #fff;
    float: right;
}

@media screen and (max-width: 767px) {
    .user-projects {
        max-width: 95%;
    }
}

/*user switch*/
.user-switch-activated {
    display: none;
}

.user-switch-activated.active {
    display: block;
}

.user-hint {
    background-color: #f2f2f2;
    padding: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    font-size: 12px;
}

.user-switch.alert-success {
    font-size: 12px;
    line-height: 18px;
}

.js--userSwitchField .js--input-error .glyphicon-remove {
    right: 15px;
}

.js--userSwitchField .js-input-username {
    padding-right: 42.5px;
}

.username-switch {
    font-weight: bold;
    color: green;
}

.user-projects__loader {
    display: none;
    position: absolute;
    z-index: 15;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
}

.loader--is-active .user-projects__loader {
    display: block;
}

.user-projects__error {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: right;
    color: #fff;
    border-bottom: 2px solid #d9534f;
    font-size: 12px;
}

.has--error .user-projects__error {
    display: block;
}

.user-projects__error p {
    background-color: #d9534f;
    display: inline-block;
    padding: 0 5px;
    margin: 0;
    position: absolute;
    bottom: -2px;
    right: 0;
}