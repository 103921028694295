@-webkit-keyframes uilsquare {
    0% {
        background-color: #587594;
    }
    1% {
        background-color: #ADCD6A;
    }
    11% {
        background-color: #ADCD6A;
    }
    21% {
        background-color: #587594;
    }
    100% {
        background-color: #587594;
    }
}

@-webkit-keyframes uilsquare {
    0% {
        background-color: #587594;
    }
    1% {
        background-color: #ADCD6A;
    }
    11% {
        background-color: #ADCD6A;
    }
    21% {
        background-color: #587594;
    }
    100% {
        background-color: #587594;
    }
}

@-moz-keyframes uilsquare {
    0% {
        background-color: #587594;
    }
    1% {
        background-color: #ADCD6A;
    }
    11% {
        background-color: #ADCD6A;
    }
    21% {
        background-color: #587594;
    }
    100% {
        background-color: #587594;
    }
}

@-ms-keyframes uilsquare {
    0% {
        background-color: #587594;
    }
    1% {
        background-color: #ADCD6A;
    }
    11% {
        background-color: #ADCD6A;
    }
    21% {
        background-color: #587594;
    }
    100% {
        background-color: #587594;
    }
}

@-moz-keyframes uilsquare {
    0% {
        background-color: #587594;
    }
    1% {
        background-color: #ADCD6A;
    }
    11% {
        background-color: #ADCD6A;
    }
    21% {
        background-color: #587594;
    }
    100% {
        background-color: #587594;
    }
}

@-webkit-keyframes uilsquare {
    0% {
        background-color: #587594;
    }
    1% {
        background-color: #ADCD6A;
    }
    11% {
        background-color: #ADCD6A;
    }
    21% {
        background-color: #587594;
    }
    100% {
        background-color: #587594;
    }
}

@-o-keyframes uilsquare {
    0% {
        background-color: #587594;
    }
    1% {
        background-color: #ADCD6A;
    }
    11% {
        background-color: #ADCD6A;
    }
    21% {
        background-color: #587594;
    }
    100% {
        background-color: #587594;
    }
}

@keyframes uilsquare {
    0% {
        background-color: #587594;
    }
    1% {
        background-color: #ADCD6A;
    }
    11% {
        background-color: #ADCD6A;
    }
    21% {
        background-color: #587594;
    }
    100% {
        background-color: #587594;
    }
}

.tp-loading {
    position: fixed;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

.tp-loading__container {
    position: fixed;
    bottom: 50%;
    left: 0;
    width: 100%;
    margin-bottom: -100px;
}

.tp-loading__loader {
    transform: scale(0.5);
    background: none;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.tp-loading__loader div {
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 40px;
    background-color: #587594;
}

.tp-loading__loader div > div {
    position: absolute;
    top: 0;
    left: 0;
    -ms-animation: uilsquare 1s linear infinite;
    -moz-animation: uilsquare 1s linear infinite;
    -webkit-animation: uilsquare 1s linear infinite;
    -o-animation: uilsquare 1s linear infinite;
    animation: uilsquare 1s linear infinite;
    width: 40px;
    height: 40px;
}

.tp-loading__loader > div:nth-of-type(1) {
    top: 30px;
    left: 30px;
}

.tp-loading__loader > div:nth-of-type(1) > div {
    -ms-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}

.tp-loading__loader > div:nth-of-type(2) {
    top: 30px;
    left: 80px;
}

.tp-loading__loader > div:nth-of-type(2) > div {
    -ms-animation-delay: 0.125s;
    -moz-animation-delay: 0.125s;
    -webkit-animation-delay: 0.125s;
    -o-animation-delay: 0.125s;
    animation-delay: 0.125s;
}

.tp-loading__loader > div:nth-of-type(3) {
    top: 30px;
    left: 130px;
}

.tp-loading__loader > div:nth-of-type(3) > div {
    -ms-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

.tp-loading__loader > div:nth-of-type(4) {
    top: 80px;
    left: 130px;
}

.tp-loading__loader > div:nth-of-type(4) > div {
    -ms-animation-delay: 0.375s;
    -moz-animation-delay: 0.375s;
    -webkit-animation-delay: 0.375s;
    -o-animation-delay: 0.375s;
    animation-delay: 0.375s;
}

.tp-loading__loader > div:nth-of-type(5) {
    top: 130px;
    left: 130px;
}

.tp-loading__loader > div:nth-of-type(5) > div {
    -ms-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.tp-loading__loader > div:nth-of-type(6) {
    top: 130px;
    left: 80px;
}

.tp-loading__loader > div:nth-of-type(6) > div {
    -ms-animation-delay: 0.625s;
    -moz-animation-delay: 0.625s;
    -webkit-animation-delay: 0.625s;
    -o-animation-delay: 0.625s;
    animation-delay: 0.625s;
}

.tp-loading__loader > div:nth-of-type(7) {
    top: 130px;
    left: 30px;
}

.tp-loading__loader > div:nth-of-type(7) > div {
    -ms-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
}

.tp-loading__loader > div:nth-of-type(8) {
    top: 80px;
    left: 30px;
}

.tp-loading__loader > div:nth-of-type(8) > div {
    -ms-animation-delay: 0.875s;
    -moz-animation-delay: 0.875s;
    -webkit-animation-delay: 0.875s;
    -o-animation-delay: 0.875s;
    animation-delay: 0.875s;
}

.loading-animation {
    text-align: center;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background: rgba(255, 255, 255, .7);
    padding: 5px;
    color: transparent;
    line-height: 27px;
}

.loading-animation__icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    font-size: 16px;
    text-indent: -9999em;
    border-top: 3px solid rgba(64, 64, 64, 0.2);
    border-right: 3px solid rgba(64, 64, 64, 0.2);
    border-bottom: 3px solid rgba(64, 64, 64, 0.2);
    border-left: 3px solid #404040;
    transform: translateZ(0);
    -webkit-animation: spinner 1.1s infinite linear;
    animation: spinner 1.1s infinite linear;
}

.loading-animation.show--block {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.show--block .loading-animation__icon {
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    position: absolute;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}