/*
* Global
*/
body {
	padding-bottom: 100px;
	position: relative;
}

@media screen and (max-width: 1200px) {
	.container {
		width: 100%;
	}
}

a {
	color: #286090;
}

a:hover,
a:focus {
	text-decoration: none;
}

.btn:focus,
.btn:active{
	outline: none !important;
}

.add-margin-top-bottom {
	margin-top: 20px;
	margin-bottom: 20px;
}

.add-padding-top-bottom {
	padding-top: 20px;
	padding-bottom: 20px;
}

.scroll-to-top {
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 100;
	top: auto;
	font-size: 20px;
	padding: 5px 5px 6px 8px;
	background: #ccc;
	color: #587594;
	opacity: .5;
	border: 1px solid #587594;
	display: none;
	cursor: pointer;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
	background-color: #587594;
}

.scroll-to-top:hover {
	opacity: 1;
}

hr {
	width: 100%;
	float: left;
}

footer {
	display: block !important;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #777;
	font-size: 12px;
}

.rpiframe-container {
	width: 0;
	height: 0;
	overflow: hidden;
	padding: 0;
	margin: 0;
}

.spinner-grow {
	display: inline-block;
	width: 30px;
	height: 30px;
	vertical-align: text-bottom;
	background-color: #587594;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: spinner-grow .75s linear infinite;
	animation: spinner-grow .75s linear infinite;
}

@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
	}
}