/*
*  orga-domains
*/

.orga-domains {
	position: relative;
	border: 1px dotted #ccc;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	padding: 10px 10px 0;
	display: inline-block;
	width: 100%;
}

.is--loading .orga-domains__loading {
	position: absolute;
	background: rgba(255, 255, 255, .6);
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
}

.orga-domains__inner {
	margin: 0 0 5px 0;
	padding: 0 0 6px 0;
	max-height: 200px;
	overflow: auto;
}

.orga-domains__controls {
	display: inline-block;
	margin: 0;
}

.orga-domains__item {
	float: left;
	width: auto;
	margin: 2px;
	background: #ddd;
	padding: 5px 30px 5px 8px;
	border: 1px solid #ddd;
	border-radius: 5px;
	position: relative;
	word-break: break-all;
}

.disabled .orga-domains__item {
	opacity: 0.65 !important;
	padding-right: 8px;
}

.orga-domains__delete {
	position: absolute;
	background: none;
	top: -1px;
	right: -2px;
	padding: 5px 8px;
	outline: none;
}

.orga-domains__text {
	margin: 0;
}

.is--inactive {
	opacity: .6;
}

.orga-domains .orga-domains__search {
	float: right;
	position: relative;
}

.orga-domains .orga-domains__add {
	float: left;
	/*padding-right: 5px;*/
	position: relative;
}

.orga-domains__add-input,
.orga-domains__search-input {
	padding-right: 30px;
}

.orga-domains__add-btn,
.orga-domains__search-btn {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
	padding-bottom: 5px;
}

.orga-domains__add-btn {
	right: -1px;
}

.orga-domains__search-btn {
	padding: 10px;
}

.orga-domains__errors {
	color: #a94442;
	padding: 5px 0 0;
	overflow: hidden;
	width: 100%;
}