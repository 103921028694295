/*
* CSV Import
*/

.import-navigation {
	margin-top: 20px;
}

.import-tabs {
	border: 1px solid #ddd;
	border-top: 0;
	padding-top: 10px;
	margin-top: -1px;
}

.import {
	font-size: 22px;
	background-color: #eee;
	border: 1px solid #ddd;
	position: relative;
	padding: 90px 10px 40px;
	margin: 20px 0;
	text-align: center;
	color: #587594;
	/*color: #ADCD6A;*/
}

.import *:focus,
.import * {
	outline: none !important;
}

.import__encoding {
	position: absolute;
	right: 30px;
	top: 30px;
}

/*.import__encoding .toggle-btn__label{*/
/*border-color: #587594;*/
/*}*/

.has-advanced-upload {
	outline: 2px dashed #587594;
	outline-offset: -10px;
	
	-webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
	transition: outline-offset .15s ease-in-out, background-color .15s linear;
}

.is--dragover:not(.is--uploading) {
	outline-offset: -20px;
	/*outline-color: #587594;*/
	background-color: #ddd;
}

.import__dragndrop,
.import__icon {
	display: none;
}

.has-advanced-upload .import__dragndrop {
	display: inline;
}

.has-advanced-upload .import__icon {
	width: 100%;
	display: block;
	margin-bottom: 40px;
	font-size: 80px;
}

.import.is--dragover .import__icon {
	opacity: .8;
}

.import.is--uploading .import__input,
.import.is--success .import__input,
.import.is--error .import__input {
	visibility: hidden;
}

.import__uploading,
.import__success,
.import__error {
	display: none;
}

.import.is--uploading .import__uploading,
.import.is--success .import__success,
.import.is--error .import__error {
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	margin-top: -15px;
}

.import__uploading {
	font-style: italic;
	-webkit-animation: pulsate 2s ease-out;
	-webkit-animation-iteration-count: infinite;
}

.import__success {
	-webkit-animation: appear-from-inside .25s ease-in-out;
	animation: appear-from-inside .25s ease-in-out;
}

@-webkit-keyframes appear-from-inside {
	from {
		-webkit-transform: translateY(-50%) scale(0);
	}
	75% {
		-webkit-transform: translateY(-50%) scale(1.1);
	}
	to {
		-webkit-transform: translateY(-50%) scale(1);
	}
}

@keyframes appear-from-inside {
	from {
		transform: translateY(-50%) scale(0);
	}
	75% {
		transform: translateY(-50%) scale(1.1);
	}
	to {
		transform: translateY(-50%) scale(1);
	}
}

@-webkit-keyframes pulsate {
	0% {
		-webkit-transform: scale(1.0, 1.0);
		opacity: 0.2;
	}
	25% {
		-webkit-transform: scale(1.05, 1.05);
		opacity: 0.5;
	}
	50% {
		-webkit-transform: scale(1.1, 1.1);
		opacity: 1;
	}
	75% {
		-webkit-transform: scale(1.05, 1.05);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1.0, 1.0);
		opacity: 0.2;
	}
}

.import__restart {
	font-weight: 700;
}

.import__restart:focus,
.import__restart:hover {
	color: #39bfd3;
}

.import__file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.import__file + label {
	max-width: 80%;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	padding-top: 120px;
	margin-top: -120px;
	position: relative;
}

.import.is--dragover .import__file + label {
	opacity: .8;
}

.import__select-button {
	font-size: 22px;
}

.import__button {
	display: none;
}

.import-results {
	width: 100%;
	padding: 0;
	margin: 0;
}

.import-resend-token {
    padding-bottom: 20px;
}

.import-results__header {
	display: block;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	margin: 0;
	padding: 0;
	position: relative;
}

.import-results__header span {
	display: block;
	float: left;
	width: 100%;
	line-height: 20px;
	word-break: break-word;
	/*border-right: 1px solid #cccccc;*/
}

.import-results .import-results__result {
	margin: 10px 0;
	border-radius: 0;
	border: 1px dashed #587594;
	position: relative;
}

.import-results .import-results__result .alert {
	border: none;
	border-top: 1px dashed;
}

.import-results .import-results__result.success {
	border-color: #3c763d;
	background-color: #dff0d8;
	padding-left: 50px;
}

.import-results .import-results__result.danger {
	border-color: #a94442;
	background-color: #f2dede;
	padding-left: 50px;
}

.import-results .import-results__result.warning {
	border-color: #8a6d3b;
	background-color: #fcf8e3;
	padding-left: 50px;
}

.import-results__icon {
	display: none;
	font-size: 34px;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
}

.import-results__icon:before {
	position: absolute;
	left: 8px;
	top: 50%;
	margin-top: -17px;
}

.danger .import-results__icon.is--danger {
	display: block;
	color: #a94442;
	border-right: 1px dashed #a94442;
}

.warning .import-results__icon.is--warning {
	display: block;
	color: #8a6d3b;
	border-right: 1px dashed #8a6d3b;
}

.success .import-results__icon.is--success {
	display: block;
	color: #3c763d;
	border-right: 1px dashed #3c763d;
}

.import-results .import-results__result .panel-heading {
	color: #000;
	background-color: #eee;
	border-color: #587594;
	border-radius: 0;
	padding: 15px;
}

.import-results .import-results__result.success .panel-heading,
.import-results .import-results__result.danger .panel-heading,
.import-results .import-results__result.warning .panel-heading {
	background-color: transparent;
}

.import-results .import-results__result.has--error {
	border-color: #a94442;
}

.import-results .import-results__result.has--error .panel-heading {
	color: #a94442;
	background-color: #f2dede;
	border-color: #a94442;
	position: relative;
}

.import-results__header__number {
	margin: 6px 5px;
	padding-left: 0;
	display: block;
	width: auto;
	float: left;
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 2px;
	font-size: 12px;
	display: none;
}

.import-results__header__left {
	padding-left: 0;
	margin: 5px 0;
}

.import-results__header__left strong {
	display: inline;
	color: #587594;
}

.import-results__header__right {
	/*display: none;*/
	position: absolute;
	right: 0px;
	top: 5px;
}

.import-results__header__right .sso-action-btn {
	padding: 3px;
	border-radius: 3px;
	font-size: 15px;
	float: left;
}

.import-results__header__ignore {
	position: absolute;
	top: 0px;
	right: 0;
	background: #ddd;
	font-size: 12px;
	color: #555;
	display: inline-block;
	line-height: 10px;
	padding: 5px;
}

.import-results__header__edit,
.import-results__header__delete {
	display: none;
}

.import-results .import-results__result .panel-collapse {
	background: #eee;
}

.import-results .form-group {
	float: left;
	width: 33.3333333%;
	font-size: 12px;
	margin: 0;
	display: block;
	min-height: 60px;
}

.import-results__result .form-horizontal .control-label {
	padding-top: 0;
	margin-bottom: 0;
	text-align: left;
	width: 100%;
}

.import-results__result .form-group > div {
	width: 100%;
	min-width: 100%;
}

.import-results__controls {
	margin-bottom: 20px;
}

.import-results__controls__btn {
	font-size: 48px;
	color: #587594;
	cursor: pointer;
	margin: 10px;
}

.import-results .form-actions {
	display: none;
}

.import-feedbacks {
	width: 100%;
	padding: 5px 0;
	position: relative;
}

.import-feedbacks__feadback {
	margin-bottom: 10px;
	position: relative;
	border-radius: 0;
	overflow: hidden;
	padding: 15px;
}

.import-feedbacks__feadback.alert-dismissable .close, .alert-dismissible .close {
	position: absolute;
	top: 15px;
	right: 10px;
	padding: 15px;
}

.import-feedbacks__feadback ul {
	padding-left: 20px;
	margin-bottom: 0;
}

.import-feedbacks__feadback ul ul {
	padding-left: 0;
}

.import-feedbacks__feadback ul ul li {
	margin-left: 20px;
}

.import-feedbacks__feadback-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, .4);
	top: 0;
	left: 0;
	text-align: center;
	padding-top: 15px;
	overflow: hidden;
	display: none;
}

.is--uploading .import-feedbacks__feadback-overlay,
.is--uploading .import__uploading {
	display: block;
}

.import-feedbacks__text {
	float: left;
	padding-bottom: 0;
	line-height: 24px;
}

.import-feedbacks__buttons {
	margin: -5px 0px -5px;
	float: right;
}

.is--text-import {
	padding: 20px 20px;
}

.is--text-import-area {
	max-width: 100%;
	width: 865px;
	min-height: 250px;
	margin: 0px;
	font-size: 12px;
}

.is--text-import-button {
	display: block;
	font-size: 24px;
	margin: 0 auto;
}

.import-feedbacks__legend {
	display: none;
	padding: 10px 0;
}

.items-contain-error .import-feedbacks__legend,
.items-contain-warning .import-feedbacks__legend {
	display: block;
}

.items-contain-error .import-feedbacks__buttons {
	display: none;
}

.import-feedbacks__legend ul {
	list-style-type: none;
	padding-left: 0;
}

.import-feedbacks__legend .import-feedbacks__legend__ok {
	color: #3c763d;
}

.import-feedbacks__legend .import-feedbacks__legend__warning {
	color: #8a6d3b;
}

.import-feedbacks__legend .import-feedbacks__legend__error {
	color: #a94442;
}

@media screen and (max-width: 992px) {
	/*.import-results__header__number {*/
	/*position: absolute;*/
	/*bottom: 0;*/
	/*}*/
}

@media screen and (max-width: 768px) {
	.import-tabs {
		border: 0 solid transparent;
		padding-top: 0;
		margin-top: 0;
	}
	
	.import-results .form-group {
		width: 50%;
	}
	
	/*.import-results__header span {*/
	/*border: none;*/
	/*}*/
}

@media screen and (max-width: 580px) {
	.has-advanced-upload .import__dragndrop {
		display: block;
	}
	
	.import-results .form-group {
		width: 100%;
	}
}
