/*
*  Navigation
*/

.sso__navigation {
	margin-bottom: 0;
	border: none;
}

.sso__nav-info {
	position: relative;
	font-weight: bold;
	text-decoration: none;
	background-color: #eee;
	border: 1px solid #ddd;
	border-radius: 0;
	min-height: 100%;
	padding: 10px;
	overflow: hidden;
}

.sso__nav-info p {
	margin: 5px 0;
}

.sso__nav-info .btn {
	margin-left: 10px;
}

.sso__nav-container {
	padding: 0;
	position: relative;
	min-height: 74px;
}

.sso__nav-container .navbar-brand {
	padding: 15px 0;
}

.sso__nav-container .navbar-brand img {
	padding: 15px 0;
	width: 80%;
	/*new design and logo*/
	/* width: 160px; */
	/*old design and logo*/
	/*width: 240px;*/
}

@media screen and (max-width: 768px) {
	.sso__nav-container .navbar-brand img {
		padding: 0;
	}
	.sso__nav-container .navbar-brand {
		padding: 10px 0;
	}
}

.sso__main-navigation {
	margin: 10px 0 0;
	text-align: center;
	border: none;
	float: left;
	width: 100%;
	background-color: transparent;
	background: none;
	border: none;
}

.sso__main-navigation .navbar-toggle {
	margin-right: 0;
}

.sso__main-navigation .navbar-collapse {
	padding: 20px 0 0;
	overflow: hidden;
}

.sso__main-navigation .navbar-nav > li > a:hover,
.sso__main-navigation .navbar-nav > li > a:focus,
.sso__main-navigation .navbar-nav > .open > a,
.sso__main-navigation .navbar-nav > .open > a:hover,
.sso__main-navigation .navbar-nav > .open > a:focus {
	color: #587594;
}

.sso__main-navigation .navbar-main > li > a {
	padding-left: 5px;
	padding-right: 5px;
	margin: 0 5px;
}

.sso__main-navigation .navbar-right > li > a {
	padding: 8px;
	margin: 8px 8px 0 -8px;
}

.sso__main-navigation > li {
	float: none;
	display: inline-block;
	border-radius: 0;
	padding-top: 7px
}

.sso__main-navigation li .sso__main-navigation__link {
	font-size: 18px;
	border: none;
	color: inherit;
	border-radius: 0;
	border-bottom: 4px solid transparent;
}

.sso__main-navigation li.active a.sso__main-navigation__link,
.sso__main-navigation li a.sso__main-navigation__link:hover,
.sso__main-navigation li.active > a.sso__main-navigation__link:hover {
	border: none;
	border-bottom: 4px solid #587594;
	color: #587594;
	background-color: transparent;
}

@media screen and (max-width: 992px) and (min-width: 767px) {
	.navbar-right__desc {
		display: none;
	}
}

@media screen and (min-width: 768px) {
	.sso__main-navigation {
		margin: 0;
	}
	
	.sso__main-navigation li .sso__main-navigation__link {
		font-size: 16px;
	}
}

@media screen and (max-width: 767px) {
	
	.sso__main-navigation li > a.sso__main-navigation__link {
		border: none;
	}
	
	.sso__main-navigation li.active a.sso__main-navigation__link,
	.sso__main-navigation li a.sso__main-navigation__link:hover,
	.sso__main-navigation li.active > a.sso__main-navigation__link:hover {
		background: #eee;
		border: none;
	}
}

.sso__right-nav {
	padding: 25px 0 20px;
	text-align: right;
	margin: 0;
	position: absolute;
	right: 30px;
	top: 0;
}

@media screen and (max-width: 580px) {
	
	.sso__nav-info .btn {
		margin: 5px 0 0;
		clear: both;
	}
	
	.sso__nav-info .btn:first-of-type {
		margin: -30px 0 0;
	}
}
