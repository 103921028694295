.is--web-app,
.is--tp-instance {
	display: none;
}

.product-type-is--NaN .is--web-app,
.product-type-is--NaN .is--tp-instance {
	display: block;
}

.product-type-is--0 .is--web-app {
	display: block;
}

.product-type-is--1 .is--tp-instance {
	display: block;
}