.sso-table {
  width: 100%;
  position: relative;
  float: left;
}

.sso-table tbody {
  border: 1px solid #e7e7e7;
}

.is--inactive-row a,
.is--inactive-row {
  font-style: italic;
  color: #808080;
}

.is--inactive-row a.sso-action-btn {
  font-style: normal;
}

.sso-table .table > thead > tr > th,
.sso-table .table tr > td {
  border: 1px solid #dddddd;
}

.sso-table .table > thead > tr > .th-actions,
.sso-table .table tr > td.user-actions {
  width: 55px;
  min-width: 55px;
  text-overflow: initial;
}

.sso-table tbody > tr > td {
  vertical-align: middle;
  max-width: 0;
  /*min-width: 120px;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*.sso-filter-mail,*/
/*.sso-filter-username {*/
/*min-width: 180px;*/
/*}*/
.sso-table th.actions {
  max-width: 55px;
  min-width: 55px;
  width: 55px;
}

.sso-action-btn {
  background-color: #eee;
  padding: 5px 8px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 20px;
}

.sso-form-actions {
  text-align: right;
}

.sso-filter__input {
  font-size: 12px;
  font-weight: normal;
  color: #333;
}

.sso-filter {
  font-size: 12px;
}

.sso-filter__btn {
  margin-top: -1px;
}

.is--not-active .sso-filter__btn,
.is--not-active .sso-filter__input {
  display: none;
}

.sso-filter-page,
.renderable.page {
  display: none;
}

.sso-filter-button {
  display: none;
}

.sso-filter-deactivated .input-group {
  width: 100%;
}

.sso-table .input-group-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  width: auto;
}

.sso-table .form-control {
  min-width: 80px;
}

.sso-table thead {
  background-color: #eeeeee;
  border-top: 1px solid #dddddd;
  border-bottom: 0;
}

.sso-table thead.is--fixed {
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  transition: all .7s ease;
}

.sso-table thead tr th,
.sso-table thead tr th.active {
  background-color: #eee;
}

.sso-table thead.collapse {
  display: table-header-group;
}

.sso-table .mobile-title {
  display: none;
}

.standard-field-text-domains {
  white-space: normal;
}

.hover-text__body,
.hover-text__copy,
.hover-text__close,
.hover-text__link {
  display: inline-block;
  background: #587594;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .3);
  color: #fff;
}

.hover-text__body a {
  color: #fff;
}


.hover-text {
  z-index: 100000;
  word-wrap: break-word;
  position: fixed;
  margin: -29px 0 0 -3px;
  max-width: 90%;
}

.hover-text__actions {

}

.hover-text__copy,
.hover-text__close,
.hover-text__link {
  padding: 5px;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
}

.hover-text__body {
  width: 100%;
  padding: 0 10px 0 3px;
  margin: 5px 0 0;
}

@media screen and (max-width: 767px) {

  .sso-table .mobile-title {
    display: inline-block;
    font-weight: bold;
  }

  .sso-table .mobile-title:after {
    content: ":";
  }

  .sso-filter-button,
  .sso-filter-button:hover,
  .sso-filter-button:focus {
    display: block;
    width: 100%;
    background-color: #ADCD6A;
    border-color: #ADCD6A;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    color: #fff;
    margin-top: 10px;
    float: left;
  }

  .sso-table tbody {
    border: none;
  }

  .sso-table .table thead {
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: hidden;
    display: block;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }

  .sso-table .table thead.collapse.in {
    height: 100%;
    visibility: visible;
    display: block;
    opacity: 1;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    margin-bottom: 20px;
  }

  .sso-table .table tbody tr {
    display: block;
    padding-bottom: 40px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-bottom: 5px solid #ddd;
  }

  .sso-table .table thead > tr,
  .sso-table .table tbody > tr,
  .sso-table .table thead > tr > th,
  .sso-table .table tbody > tr > td {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: block;
    white-space: normal;
  }

  .sso-table .table tbody > tr > td {
    border: none;
    padding: 10px 10px 0;
  }

  .sso-table .table thead > tr > th {
    display: none;
  }

  .sso-table .table thead > tr > th.active {
    display: block;
  }

  .sso-table .table tbody > tr > td.user-actions,
  .sso-table .table tbody > tr > td.orga-actions,
  .sso-table .table tbody > tr > td.instance-actions {
    margin-top: -15px;
    float: right;
    width: auto;
    min-width: 0;
  }

}

@media screen and (max-width: 580px) {
  .sso-table .table tr > td span,
  .sso-table .table tr > td a {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 268px;
    display: inline-block;
    line-height: 20px;
  }
}

.federation-list {
  margin-top: 15px;
}	
