/*
* SSO Form
*/
form {
  position: relative;
}

input:read-only {
  cursor: not-allowed;
}

input[type="radio"], input[type="checkbox"] {
  margin: 1px 0 0;
  line-height: normal;
}

.btn {
  padding: 6px 12px 5px;
}

.form-group textarea {
  max-width: 100%;
  min-width: 100%;
}

input, button,
.form-control,
.btn-group > .btn,
.btn-group > .dropdown-toggle,
.btn {
  border-radius: 0;
  z-index: 1;
}

fieldset {
  padding: 0;
  display: block;
  width: 100%;
  clear: both;
}

@media screen and (min-width: 768px) {
  fieldset {
    padding: 0 15%;
  }
}

.toggle-btn__label {
  position: relative;
  overflow: hidden;
}

.toggle-btn__label__text {
  position: relative;
  display: block;
  z-index: 1;
  float: left;
}

.toggle-btn__label__input {
  visibility: hidden;
  position: absolute;
}

.toggle-btn__label__input:checked ~ .toggle-btn__label__active {
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 100%;
  background-color: #ADCD6A;
}

.btn-primary {
  background-color: #587594;
  border-color: #587594;
}

div.required .control-label:after {
  content: "*";
  position: absolute;
  right: 5px;
  top: 5px;
}

.form-horizontal .control-label {
  margin-bottom: 5px;
}

legend {
  overflow: hidden;
  float: left;
  padding: 20px 0 5px;
  margin-bottom: 25px;
}

.readonly__desc {
  cursor: not-allowed;
  min-height: 33px;
  display: block;
  color: #777;
  line-height: 22px;
  margin: 0;
  padding: 5px 8px;
}

.form-horizontal .form-group {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.form-control {
  padding: 6px;
  height: 33px;
}

.form-control.is--loading {
  border: 1px solid rgba(0, 0, 0, .05);
  background: rgba(0, 0, 0, .02);
  box-shadow: none !important;
}

.btn-white-inverse {
  background: none;
  background-color: transparent;
  border: 1px solid #999;
  color: #999;
}

.btn-white-inverse:hover {
  background: none;
  border: 1px solid #666;
  color: #666;
}

.form-control-feedback,
.sso-field-error {
  display: none;
}

.has-error .form-control-feedback,
.has-error .sso-field-error,
.sso-field-warning {
  display: block;
}

.sso-field-error,
.sso-field-warning {
  color: #a94442;
  padding: 5px 0;
  font-size: 12px;
}

.sso-field-warning {
  color: #8a6d3b;
}

.user-pwd-policy {
  border-left: 2px solid #ADCD6A;
  background-color: #f2f2f2;
  padding: 10px 0;
  overflow: hidden;
  margin-bottom: 15px;
  font-size: 12px;
}

.user-pwd-policy p {
  margin: 0 25px 5px;
}

.user-pwd-policy ul {
  margin: 0;
}

.user-activation .js--input-error {
  clear: both;
  width: 100%;
}

.user-activation.has-error .user-activation__field {
  border: 1px solid #a94442;
  overflow: hidden;
  padding: 1px;
  box-sizing: border-box;
}

.user-activation.has-error .form-control-feedback {
  top: 1px;
}

.user-activation__field {
  position: relative;
  width: 100%;
}

.user-activation__field .toggle-btn__label {
  float: left;
}

@media screen and (max-width: 992px) {
  .readonly__desc {
    min-height: 38px;
  }
}

/*
* read-only-form
 */
.read-only-form .feedback-container {
  margin: 20px 0 0;
}

.read-only-form .hide-on-read-only {
  display: none;
}

.show-on-read-only {
  display: none;
}

.read-only-form .show-on-read-only {
  display: block;
}

.read-only-form .form-group {
  margin-bottom: 0;
}

.read-only-form .form-group input,
.read-only-form .chosen-container,
.read-only-form .form-group select {
  border: none;
  box-shadow: none;
  border: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.read-only-form .chosen-container .chosen-single div b {
  display: none;
}

.read-only-overlay {
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .1);
}